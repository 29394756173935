import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Menu from '../types/Menu';

type MenuResponse = Menu[];

export const api = createApi({
    reducerPath: 'api',
    tagTypes: ['Menu', 'Order'],
    baseQuery: fetchBaseQuery({
        baseUrl: '/services'
    }),
    endpoints: (builder) => ({
        getMenu: builder.query<MenuResponse, void>({
            query: () => '?action=menu',
            providesTags: ['Menu'],
        }),
        placeOrder: builder.mutation({
            query: param => ({
                url: `?action=order`,
                method: 'POST',
                body: param
            }),
            invalidatesTags: ['Order']
        })
    })
});


export const { useGetMenuQuery, usePlaceOrderMutation } = api;

export const { endpoints, reducerPath, reducer, middleware } = api