import React, {useEffect, useReducer, useState} from 'react';
import { v4 as uuid } from 'uuid';
import './App.scss';
import { useGetMenuQuery, usePlaceOrderMutation } from './api/orderApi';
import SearchIcon from './icons/SearchIcon';
import CloseIcon from './icons/CloseIcon';
import { useDispatch, useSelector } from "react-redux";
import { addItem, editItem, deleteItem, emptyItems } from "./store/reducer";
import OrderItem from "./types/OrderItem";
import LoadingIcon from "./icons/LoadingIcon";
import { RootState } from "./store/store";


function App() {
  const { data: menuItem, isFetching: menuFetching, isSuccess: menuSuccess, refetch: menuRefetch } = useGetMenuQuery();
  const availableMenu = menuSuccess && {...menuItem};

  const [ placeOrder, { isSuccess: orderSuccess } ] = usePlaceOrderMutation();
  const [ isShow, setShow ] = useState<boolean>(false);
  const [ isShowRemark, setShowRemark ] = useState<boolean>(false);
  const [ isShowTotalRemark, setShowTotalRemark ] = useState<boolean>(false);
  const [ isExpand, setExpand ] = useState<boolean>(false);
  const [ isModal, setModal ] = useState<boolean>(false);
  const dispatch = useDispatch();
  const orderItems = useSelector((state: RootState) => state.app.orderItems);
  const [ total, setTotal ] = useReducer(
      (prev, state) => ({ ...prev, ...state}),
      { totalAmount:0, totalDiscount: 0, totalReceived:0, remark: '' }
  )
  const [ selectedItem, setSelectedItem ] = useState<OrderItem>({
    chinese_name: '',
    fk_category: null,
    menu_id: null,
    discount: 0.00,
    quantity: 1,
    price: 0.00,
    totalPrice: 0.00,
    remark:''
  });

  const [ selectedEdit, setSelectedEdit ] = useState<OrderItem>({
    chinese_name: '',
    fk_category: null,
    menu_id: null,
    discount: 0.00,
    quantity: 1,
    price: 0.00,
    totalPrice: 0.00,
    remark:''
  });

  const [ keyword, setKeyword ] = useState<null | string>(null);
  const [ highlightMenu, setHighlightMenu] = useState<null | OrderItem[]>([]);

  const showAutoComplete = (toggle) =>{
    setShow(toggle);
    setHighlightMenu([]);
    setKeyword('');
    document.body.classList.add(toggle ? 'overflow-hidden':'overflow-auto');
    document.body.classList.remove(!toggle ? 'overflow-hidden':'overflow-auto');
    setTimeout(()=>setExpand(toggle),50);
  }

  const showModal = (toggle) =>{
    setModal(toggle);
    document.body.classList.add(toggle ? 'overflow-hidden':'overflow-auto');
    document.body.classList.remove(!toggle ? 'overflow-hidden':'overflow-auto');
  }

  const selectMenu = (item: OrderItem) =>{
    setSelectedItem({...selectedItem, ...Object.assign({},item, {totalPrice: item.price })});
    showAutoComplete(false);
  }

  const editOrder = (props) => {
    if (props.quantity || props.quantity === ''){
      setSelectedEdit({
        ...selectedEdit,
        ...{ quantity: parseInt(props.quantity || 0),
          totalPrice: (Number(((props.quantity || 0) * selectedEdit.price ) - selectedEdit.discount).toFixed(2) as unknown as number )
        }
      });
    }else if (props.discount || props.discount === ''){
      setSelectedEdit({
        ...selectedEdit,
        ...{ discount: props.discount as unknown as number,
          totalPrice: (Number((selectedEdit.quantity * selectedEdit.price) - (props.discount.replace(/^0+/, "") || 0)).toFixed(2) as unknown as number) }
      });
    }else if (props.totalPrice || props.totalPrice === '') {
      setSelectedEdit({
        ...selectedEdit,
        ...{totalPrice: props.totalPrice || 0}
      });
    }else if (props.remark || props.remark === ''){
        setSelectedEdit({ ...selectedEdit, ...{ remark: props.remark }})
    }
  }

  const editSelected = (props) => {
    if (props.quantity || props.quantity === ''){
     setSelectedItem({
       ...selectedItem,
       ...{ quantity: parseInt(props.quantity || 0),
         totalPrice: (Number(((props.quantity || 0) * selectedItem.price) - selectedItem.discount).toFixed(2) as unknown as number )
       }
     });
    }else if (props.discount || props.discount === ''){
      setSelectedItem({
        ...selectedItem,
        ...{ discount: props.discount as unknown as number,
          totalPrice: (Number((selectedItem.quantity * selectedItem.price) - (props.discount.replace(/^0+/, "") || 0)).toFixed(2) as unknown as number) }
      });
    }else if (props.totalPrice || props.totalPrice === '') {
      setSelectedItem({
        ...selectedItem,
        ...{totalPrice: props.totalPrice || 0}
      });
    }else if (props.remark || props.remark === ''){
      setSelectedItem({ ...selectedItem, ...{ remark: props.remark }})
    }else{
      setSelectedItem({...selectedItem, ...{ quantity: props.quantity || 1, totalPrice: ((selectedItem.quantity || 1) * selectedItem.price) - props.discount}});
    }
  }

  const handleTotalRemark = (props) => {
    setTotal({
      ...total,
      ...{ remark: props.remark }
    })
  }

  const handleAddItem = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>):void => {
    evt.preventDefault();
    dispatch(addItem(selectedItem));
    setShowRemark(false);
    setSelectedItem({chinese_name: '',
      fk_category: null,
      menu_id: null,
      discount: 0.00,
      quantity: 1,
      price: 0.00,
      totalPrice: 0.00,
      remark:''
    });
  }

  const handleEditItem  = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>):void => {
    evt.preventDefault();
    dispatch(editItem(selectedEdit));
    showModal(false);

    setSelectedEdit({chinese_name: '',
      fk_category: null,
      menu_id: null,
      discount: 0.00,
      quantity: 1,
      price: 0.00,
      totalPrice: 0.00,
      remark:''
    });
  }

  const handleDelete = (item:OrderItem):void => {
    dispatch(deleteItem(item));
  }

  const handleSubmit = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>):void => {
    evt.preventDefault();


  placeOrder(
          Object.assign(
              {},
              { items: orderItems},
              {
                'total_amount': total.totalAmount,
                'total_discount': total.totalDiscount,
                'received': total.totalReceived,
                'remark': total.remark
              }
          )
      ).then((res)=>{
        //reset after submit
        alert('Success');
        setShow(false);
        setShowRemark(false);
        setShowTotalRemark(false);
        setExpand(false);
        setModal(false);
        setSelectedEdit({
          chinese_name: '',
          fk_category: null,
          menu_id: null,
          discount: 0.00,
          quantity: 1,
          price: 0.00,
          totalPrice: 0.00,
          remark:''
        });
        setSelectedItem({chinese_name: '',
          fk_category: null,
          menu_id: null,
          discount: 0.00,
          quantity: 1,
          price: 0.00,
          totalPrice: 0.00,
          remark:''
        });
        menuRefetch();
        dispatch(emptyItems());
        setTotal({
          totalAmount:0,
          totalDiscount: 0,
          totalReceived: 0,
          remark: ''
        })
      })





  }

  useEffect(()=>{
    let inlineAmount = orderItems.reduce((prev, next) => Number(prev) + Number(next.price), 0);
    let inlineDiscount = orderItems.reduce((prev, next) => Number(prev) + Number(next.discount), 0);
    setTotal({
      totalAmount: inlineAmount.toFixed(2),
      totalDiscount: inlineDiscount.toFixed(2),
      totalReceived: (inlineAmount - inlineDiscount).toFixed(2)
    })
  }, [orderItems]);

  useEffect(()=>{
    if (keyword) {
      if ((/^\d/).test(keyword)){
        setHighlightMenu(Object.keys(availableMenu)
            .map((key) => {
              if (availableMenu[key][Number(keyword)-1]){
                return availableMenu[key][Number(keyword)-1];
              }
            }).filter(item=> item)
        )
      }else if ((/^[a-dA-D][\d*$]/).test(keyword)){
        let [category, index] = keyword.split(/(\d+)/);
        if (category && index){
          setHighlightMenu([availableMenu[category.toUpperCase()][Number(index)-1]]);
        }
      }else if((/^[a-dA-D]/).test(keyword)){
        setHighlightMenu(availableMenu[keyword.toUpperCase()]);
      }
    }else{
      setHighlightMenu([]);
    }

  }, [keyword]);

  const setHighlight = (evt: React.ChangeEvent<HTMLInputElement>):void =>{
    showAutoComplete(true);
    setKeyword(evt.target.value);

  }

  return (
    <div className={`App flex justify-content-center antialiased h-screen md:h-full
    ${isModal ? 'before:fixed before:inset-0 before:z-10 before:block before:w-full before:h-full before:bg-black before:bg-opacity-50' : ''}`}>
      <form className='flex flex-col justify-content-center mx-auto bg-white min-w-300 w-full md:max-w-3xl md:mt-4 md:mb-10 md:border p-8'>
        <div className='relative my-2'>
          <label htmlFor="search" className="relative text-gray-400 focus-within:text-gray-600 block">
            <LoadingIcon
                className={`h-6 w-6 absolute right-3 top-2  animate-spin 
                  ${menuFetching ? 'opacity-100' : 'opacity-0'}`
                }
               />
            <SearchIcon
                className={`pointer-event-none absolute top-1/2 transform -translate-y-1/2 right-3 transition-all 
                ${menuFetching ? 'opacity-0' : ''}
                ${menuSuccess && !isShow ? ' opacity-40':'opacity-0'}`}
            />
            <CloseIcon
                className={`z-10 h-10 w-10 p-2 absolute top-1/2 transform -translate-y-1/2 right-0 transition-all ${ menuSuccess && isShow ? 'opacity-40':'opacity-0 pointer-event-none'}`}
                onClick={()=>{
                  showAutoComplete(false)
                }}
            />
            <input
                type="text"
                id='search'
                className={`border-0 ring-1 ring-slate-900/20 text-slate-500 rounded-lg form-input p-2 w-full placeholder:text-slate-400 ${menuFetching ? 'pointer-event-nones':'pointer-events-auto'}`}
                placeholder={`${menuFetching ? 'Loading' : 'Type menu'}`}
                onClick={ ()=> !isExpand && showAutoComplete(true) }
                autoComplete='off'
                value={ keyword ? keyword : selectedItem.chinese_name ? selectedItem.chinese_name : '' }
                onKeyDown={
                  (evt: React.KeyboardEvent<HTMLInputElement>)=>{
                    if ((!/^[0-9a-dA-D]+$/.test(evt.key)) && evt.key !== 'Backspace') {
                      evt.preventDefault();
                    }
                  }
                }
                onChange={ setHighlight }
            />

          </label>
          <div className={` absolute z-10  py-1 pb-24 px-2 w-full transition-all duration-250 overflow-y-auto ${!isShow ? ' opacity-0':'opacity-1'} ${!isExpand ? 'max-h-0 pointer-events-none':'max-h-screen pointer-events-all'}`}>
            <div className='bg-white border'>
            { menuSuccess && Object.entries(availableMenu).map(([key, value], categoryInd) => {

                return (
                    <div key={uuid()} className={`divide-y pb-3 
                    ${(highlightMenu && highlightMenu.length > 0 && highlightMenu.find(menu=>menu.fk_category===(categoryInd+1)) || highlightMenu && highlightMenu.length ===0)  ? 'block':'hidden'}
                    `}>
                      <div key={uuid()}>
                        <h4 className='text-slate-400 font-weight-bolder text-sm px-3 pt-2'>
                          Category {key}
                        </h4>
                        <div className={`${highlightMenu && highlightMenu.length == 1 ? '': 'divide-y'}`}>
                          { availableMenu[key].map((item, index) => {
                            return <p key={uuid()}
                                      className={`py-2 px-3 text-slate-600 cursor-pointer
                                      ${((highlightMenu && highlightMenu.length > 0 && highlightMenu.includes(item)) || highlightMenu && highlightMenu.length ===0)  ? 'block':'hidden'}
                                      `}
                                      onClick={()=>selectMenu(item)}>
                                      <span className='text-sm font-bold mr-1'>{index+1}</span>
                                        {item.chinese_name}

                                    </p>
                          })}
                        </div>
                      </div>
                    </div>
                );
            }
            ) }
            </div>
          </div>
        </div>
        <div className='flex flex-row gap-6 my-2'>
          <div className='relative'>
            <label htmlFor='quantity' className='uppercase text-xs font-bold text-slate-600'>Quantity</label>
            <input type="text"
                   id='quantity' pattern="^[0-9]*$"
                   className="border-0 ring-1 ring-slate-900/20 text-slate-500 rounded-lg form-input w-full p-2 placeholder:text-slate-400"
                   value={selectedItem.menu_id ? selectedItem.quantity : 1 }
                   onChange={(evt)=>{
                     editSelected({quantity: evt.target.value})
                   }}
            />
          </div>
          <div className='relative'>
            <label htmlFor='discount' className='uppercase text-xs font-bold text-slate-600'>Discount</label>
            <input type="text"
                   id='discount'
                   className="border-0 ring-1 ring-slate-900/20 text-slate-500 rounded-lg form-input p-2 w-full placeholder:text-slate-400"
                   value={selectedItem.menu_id ? selectedItem.discount : 0 }
                   onChange={(evt)=>{
                     editSelected({discount: evt.target.value.replace(/^0+/,"") as any} )
                   }}
            />
          </div>
          <div className='relative'>
            <label htmlFor='price' className='uppercase text-xs font-bold text-slate-600'>Price</label>
            <input type="text"
                   id='price'
                   className="border-0 ring-1 ring-slate-900/20 text-slate-500 rounded-lg form-input p-2 w-full placeholder:text-slate-400"
                   value={selectedItem.menu_id ? selectedItem.totalPrice ? selectedItem.totalPrice : 0.00 : 0.00 }
                   onChange={(evt)=>{
                     editSelected({totalPrice: evt.target.value as any} )
                   }}
            />
          </div>
        </div>
        <div className='relative text-center my-4'>
          <button className='inline-flex items-center h-9 rounded-full text-sm font-semibold px-3 focus:outline-none focus:ring-2 bg-pink-50 text-pink-600 hover:bg-pink-100 hover:text-pink-700 focus:ring-pink-600 cursor-pointer mb-4' onClick={ (evt)=> {  evt.preventDefault(); setShowRemark(!isShowRemark); if (!isShowRemark){editSelected({remark: ''} )} } }>{isShowRemark  ? 'Close' : 'Add'} remark</button>
          <input
              type="text"
              className={`border-0 ring-1 ring-slate-900/20 text-slate-500 rounded-lg form-input p-2 w-full placeholder:text-slate-400 ${isShowRemark ? 'block':'hidden'}`}
              placeholder='Type Remark'
              value={ selectedItem.remark || '' }
              onChange={(evt)=>{ editSelected({remark: evt.target.value} ) }}
          />
        </div>
        <div className='relative mb-4 flex md:ml-auto md:mr-0'>
          <button
              className='d-block py-4 md:py-3 px-8 bg-cyan-500 text-white text-sm font-semibold w-full md:w-auto rounded-md shadow-lg shadow-cyan-500/50 focus:outline-none'
              onClick={ handleAddItem }
          >Add</button>
        </div>
        { orderItems.map((item)=>{
          return (
              <div key={uuid()} className='flex flex-col rounded-md my-3 shadow-sm border p-4 w-full items-center'>
                <div className='flex flex-row w-full'>
                  <div className={`
                    ${item.fk_category === 1 && 'bg-amber-500'}
                    ${item.fk_category === 2 && 'bg-lime-500'}
                    ${item.fk_category === 3 && 'bg-fuchsia-500'}
                    ${item.fk_category === 4 && 'bg-indigo-500'}
                    w-14 h-14 text-white text-6xl font-bold flex items-center justify-center rounded-md mr-4`}>
                    { item.fk_category &&
                      String.fromCharCode((item.fk_category + 64))
                    }
                  </div>

                  <div className='flex flex-col flex-1 justify-between'>
                    <div className='flex flex-row items-end'>
                      <strong className='text-xl md:text-2xl leading-5 md:leading-6 text-slate-500 font-light w-36'>{item.chinese_name}</strong>

                      <em className='text-xs text-slate-600 hidden md:block ml-auto mr-0 self-end'>price</em>
                      <strong className='text-xl leading-5 ml-auto md:ml-2 mr-2'>RM {item.price.toFixed(2)}</strong>

                      <div className='flex items-end'>
                        <em className='text-xs text-slate-600 mr-2 hidden md:block'>discount</em>
                        <em className='text-xl text-slate-600 mr-2 font-bold md:hidden leading-5'>-</em>
                        <strong className='text-xl leading-none'><span className='text-xs'>RM </span> {item.discount}</strong>
                      </div>
                    </div>

                    <div className='flex flex-row w-full justify-between items-end'>
                      <div className='flex items-end'>
                        <em className='text-xs text-slate-600 mr-2'>quantity</em>
                        <strong className='text-xl leading-none'>x{item.quantity}</strong>
                      </div>

                      <div className='flex flex-col'>
                        <strong className='text-3xl flex leading-6 items-end'>
                          <span className='text-xs mr-2'>RM </span>{item.totalPrice}
                        </strong>
                      </div>

                    </div>
                  </div>

                </div>

                  {item.remark !== '' &&
                  <div className='flex items-end w-full mt-4 leading-4'>
                    Remark: <em className='text-xs text-slate-600 ml-2'>{item.remark}</em>
                  </div>
                  }

                <div className='mt-4 flex gap-6 w-full md:justify-end'>
                  <button className='d-block py-4 md:py-3 px-8 bg-rose-500 text-white text-sm font-semibold w-full md:w-auto rounded-md shadow-lg  focus:outline-none' onClick={ (evt)=> {evt.preventDefault(); handleDelete(item)} }>Delete</button>
                  <button
                      className='d-block py-4 md:py-3 px-8 bg-emerald-500 text-white text-sm font-semibold w-full md:w-auto rounded-md shadow-lg  focus:outline-none'
                    onClick={ (evt)=>{
                      evt.preventDefault();
                      setModal(true);
                      setSelectedEdit(item)
                    }}
                  >Edit</button>
                </div>
              </div>
          )
        }) }

        {orderItems.length > 0 &&
            <>
              <div className='flex flex-row items-end justify-around my-4 p-2 md:p-4'>
                <div>
                  <em className='text-xs text-slate-600 hidden md:block ml-auto mr-0 self-end'>Total Price</em>
                  <strong className='text-3xl'><span className='text-xs'>RM </span>
                    {total.totalAmount}
                  </strong>
                </div>

                <div>
                  <em className='text-xs text-slate-600 hidden md:block ml-auto mr-0 self-end'>Total Discount</em>
                  <strong className='text-3xl'><span className='text-xs'>RM </span>
                    {total.totalDiscount}
                  </strong>
                </div>

                <div>
                  <em className='text-xs text-slate-600 hidden md:block ml-auto mr-0 self-end'>Received</em>
                  <strong className='text-3xl'><span className='text-xs'>RM </span>
                    { total.totalReceived }
                  </strong>
                </div>
              </div>
              <div className='relative text-center my-4  border-b mb-4 md:mb-6'>
                <button className='inline-flex items-center h-9 rounded-full text-sm font-semibold px-3 focus:outline-none focus:ring-2 bg-pink-50 text-pink-600 hover:bg-pink-100 hover:text-pink-700 focus:ring-pink-600 cursor-pointer mb-4' onClick={ (evt)=> { evt.preventDefault(); setShowTotalRemark(!isShowTotalRemark); if (!isShowTotalRemark){handleTotalRemark({remark: ''} )} } }>{isShowTotalRemark  ? 'Close' : 'Add'} remark</button>
                <input
                    type="text"
                    className={`border-0 ring-1 ring-slate-900/20 text-slate-500 rounded-lg form-input p-2 w-full placeholder:text-slate-400 mb-4 md:mb-6 ${isShowTotalRemark ? 'block':'hidden'}`}
                    placeholder='Type Remark'
                    value={ total.remark || '' }
                    onChange={(evt)=>{ handleTotalRemark({remark: evt.target.value} ) }}
                />
              </div>

            </>
        }

        { orderItems.length > 0 &&
          <div className='relative mb-4 flex md:ml-auto md:mr-0'>
            <button
                className='d-block py-4 md:py-3 px-8 bg-cyan-500 text-white text-sm font-semibold w-full md:w-auto rounded-md shadow-lg shadow-cyan-500/50 focus:outline-none'
                onClick={ handleSubmit }
            >Submit Order</button>
          </div>
        }

      </form>

      <div className={`fixed z-10 right-0 px-2 left-0 top-4 z-50 justify-center items-center md:h-full md:inset-0 ${isModal && orderItems.length > 0 ? 'flex' : 'hidden'}`}>
        <div className='relative p-8 bg-white rounded-lg shadow w-4/5 md:w-50 md:max-w-xl'>
          <CloseIcon onClick={
            (evt)=> {
            setModal(false);
            setSelectedEdit({
              chinese_name: '',
              fk_category: null,
              menu_id: null,
              discount: 0.00,
              quantity: 1,
              price: 0.00,
              totalPrice: 0.00,
              remark:''})
            }
          } className='absolute top-3 right-3 w-5 h-5 opacity-4' />
          <form>
            <div className='relative flex items-center gap-4 my-3'>
              <strong className='uppercase text-xs text-slate-600 text-right w-1/3'>Item</strong> <div className='w-full'>{selectedEdit?.chinese_name}</div>
            </div>

            <div className='relative flex items-center gap-4 my-3'>
              <label htmlFor='editQuantity' className='uppercase text-xs font-bold text-slate-600 text-right w-1/3'>Quantity</label>
              <input type="text"
                     id='editQuantity' pattern="^[0-9]*$"
                     className="border-0 ring-1 ring-slate-900/20 text-slate-500 rounded-lg form-input w-full p-2 placeholder:text-slate-400"
                     value={selectedEdit.menu_id ? selectedEdit.quantity : 1 }
                     onChange={(evt)=>{
                       editOrder({quantity: evt.target.value})
                     }}
              />
            </div>

            <div className='relative flex items-center gap-4 my-3'>
              <label htmlFor='editDiscount' className='uppercase text-xs font-bold text-slate-600 text-right w-1/3'>Discount</label>
              <input type="text"
                     id='editDiscount'
                     className="border-0 ring-1 ring-slate-900/20 text-slate-500 rounded-lg form-input w-full p-2"
                     value={selectedEdit.discount ? selectedEdit.discount : 0 }
                     onChange={(evt)=>{
                       editOrder({discount: evt.target.value.replace(/^0+/,"") as any})
                     }}
              />
            </div>

            <div className='relative flex items-center gap-4 my-3'>
              <label htmlFor='editPrice' className='uppercase text-xs font-bold text-slate-600 text-right w-1/3'>Price</label>
              <input type="text"
                     id='editPrice'
                     className="border-0 ring-1 ring-slate-900/20 text-slate-500 rounded-lg form-input p-2 w-full"
                     value={selectedEdit.menu_id ? selectedEdit.totalPrice ? selectedEdit.totalPrice : 0.00 : 0.00 }
                     onChange={(evt)=>{
                       editOrder({totalPrice: evt.target.value as any} )
                     }}
              />
            </div>


            <div className='relative flex items-start gap-4 my-3'>
              <label htmlFor='editRemark' className='uppercase text-xs font-bold text-slate-600 text-right w-1/3'>Remark</label>
              <textarea
                  id='editRemark'
                  className="border-0 ring-1 ring-slate-900/20 text-slate-500 rounded-lg form-input p-2 w-full"
                  value={ (selectedEdit?.remark && selectedEdit.remark) || '' }
                  onChange={(evt)=>{ editOrder({remark: evt.target.value} ) }}
              />
            </div>

            <div className='relative mt-4 flex justify-end'>
              <button
                  className='d-block py-4 md:py-3 px-8 bg-cyan-500 text-white text-sm font-semibold w-full md:w-auto rounded-md shadow-lg shadow-cyan-500/50 focus:outline-none'
                  onClick={ handleEditItem }
              >Update</button>
            </div>
          </form>

        </div>

      </div>

    </div>
  );
}

export default App;
