import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import reducer from './reducer';
import logger from 'redux-logger';
import { api } from '../api/orderApi';

export const store = configureStore({
    reducer,
    middleware:(getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(logger, api.middleware),
    devTools: true
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
