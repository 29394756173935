import {createSlice, current, PayloadAction} from '@reduxjs/toolkit';
import Menu from '../types/Menu';
import OrderItem from '../types/OrderItem';
import { api } from '../api/orderApi';

export interface OrderState {
    status: 'idle' | 'submitting' | 'submitted' | 'failed';
    menuItems: Menu[],
    totalAmount: number;
    totalDiscount: number;
    received: number;
    remark: string;
    orderItems: OrderItem[]
}

const initialState: OrderState = {
    status: 'idle',
    menuItems:[],
    totalAmount: 0,
    totalDiscount: 0,
    received: 0,
    remark: '',
    orderItems:[]
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers:{
        addItem:(state, { payload }: PayloadAction<OrderItem>) => {
            state.orderItems.push(payload);
        },
        editItem:(state, { payload }) =>{
            const index = state.orderItems.findIndex( item => item.menu_id === payload.menu_id );
            state.orderItems[index]= payload;
        },
        deleteItem:(state, { payload }: PayloadAction<OrderItem>) => {
            state.orderItems = state.orderItems.filter(item=> item.menu_id !== payload.menu_id )
        },
        emptyItems:(state) => {
            state.orderItems = [];
        }
    }
});


export const { actions, reducer } = appSlice;
export const { addItem, editItem, deleteItem, emptyItems } = actions;
export default ({app: reducer, [ api.reducerPath ]: api.reducer });
